import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import Viber_Logo from "../../image/Viber_Logo.png";
import Telegram_Logo from "../../image/Telegram_Logo.png";
import QRCode from "react-qr-code";
import {useSelector} from "react-redux";
import IconFile from "../../Icons/IconFile";
import Payment from "./Payment";
import useWindowSize from "../../hook/useWindowSize";
import {LS_IS_DISPLAY_MODULE_SALORY, LS_USER_ID, LS_WORKER_ID, SALARY_PROJECT_ACCESS_ROLE} from "../../utils/constants";
import {getIsAccessModuleSalaryByUserRoleAndHasWorker} from "../../store/selectors/auth_selector";
import PaymentAdditional from "./PaymentAdditional";

const AsideMenu = (props) => {
    const size = useWindowSize();
    const settings = useSelector(state => state.main.settings_project)
    const viberQr = props.settings?.['production.link_bot_viber'] || ''
    const telQr = props.settings?.['production.link_bot_telegram'] || ''
    const isAccessSalaryModule = useSelector(getIsAccessModuleSalaryByUserRoleAndHasWorker(SALARY_PROJECT_ACCESS_ROLE));


    const is_user_authorized = !!Number(localStorage.getItem(LS_USER_ID));

    const logoDt = useMemo(() => {
        let is_worker = props?.worker;
        return is_worker ? props?.worker : props?.user;
    }, [props?.user, props?.worker]);


    return (<aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="button-container bg-white">
            <a href="/" className="brand-link w-100">

                {!!(logoDt?.logo) ? <img src={logoDt.logo} style={{maxHeight: '50px'}}/> : <div className="logo"></div>}

            </a>
            {size.width <= 991 && (<span
                className="brand-link text-dark"
                data-widget="pushmenu"
                // href="#"
                role="button"
                onClick={() => props.toggleSidebar(!props.sidebarCollapse)}
            >
                <span>X</span>
            </span>)}
        </div>

        <div className="sidebar">
            {/*{is_user_authorized && <>*/}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                    <span className="fas fa-user"></span>
                </div>
                <div className="info">
                    <a href="#" className="d-block">
                        {is_user_authorized ?  props.user.name : props?.worker?.name}
                    </a>
                </div>
            </div>
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                    <span className="fas fa-user-tag"></span>
                </div>
                <div className="info">
                    <small className={"menu-user-role"}>
                        {is_user_authorized ? props.user?.role?.map((e, i) => {
                            return i === 0 ? e.name : " | " + e.name;
                        }) : props.getT("Работник")}
                    </small>
                </div>
            </div>
            {/*</>}*/}
            <nav className="mt-2">
                <ul className="nav">
                    {viberQr && <li className="nav-item">
                        <a href="#" target={"_blank"}>
                <span className="header_left_button btn">
                  <img
                      style={{width: "26px", height: "26px"}}
                      src={Viber_Logo}
                      alt=""
                  />
                </span>
                            <div>
                                <QRCode value={viberQr} size={100}/>
                                <div
                                    style={{color: "white", marginTop: "10px"}}
                                    className="small"
                                >
                                    {props.getT("Отсканируйте с телефона")}
                                </div>
                            </div>
                        </a>
                    </li>}
                    {telQr && <li className="nav-item">
                        <a href="#" target={"_blank"}>
                                <span className="header_left_button btn">
                                                  <img
                                                      style={{width: "26px", height: "26px"}}
                                                      src={Telegram_Logo}
                                                      alt=""
                                                  />
                                </span>
                            <div>
                                <QRCode value={telQr} size={100}/>

                                <div
                                    style={{color: "white", marginTop: "10px"}}
                                    className="small"
                                >
                                    {props.getT("Отсканируйте с телефона")}
                                </div>
                            </div>
                        </a>
                    </li>}
                </ul>
                <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="true"
                >
                    {is_user_authorized && <>
                    <li className="nav-item menu-open">
                        <Link
                            to={"/dashboard"}
                            className={"nav-link" + (props.menu.active === "dashboard" ? " active" : "")}
                            onClick={() => props.toggleMenu("dashboard")}
                        >
                            <i className="nav-icon fas fa-tachometer-alt"></i>
                            <p>{props.getT('Главная')}</p>
                        </Link>
                    </li>
                    <li
                        className={"nav-item" + (props.menu.active === "orders" ? " menu-open" : "")}
                    >
                        <a
                            className={"nav-link" + (props.menu.active === "orders" ? " active" : "")}
                            onClick={() => props.toggleMenu("orders")}
                        >
                            <i className="nav-icon fas fa-inbox"></i>
                            <p>
                                {props.getT("Заказы")}
                                <i className="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to={"/orders/add"} className="nav-link btn btn-success">
                                    <i className="fas fa-plus-circle text-white"></i>
                                    <p>{props.getT('Cоздать заказ')}</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/orders/actual"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>
                                        {props.getT("Актуальные")}
                                        {props.counts && props.counts.actual ? "(" + props.counts.actual + ")" : ""}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/orders/search"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT('Поиск заказа')}</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/orders/group"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT('Задания на производство')}</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/orders/production"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>
                                        {props.getT(`В производство`)}
                                        {props.counts && props.counts.production ? "(" + props.counts.production + ")" : ""}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/orders/problem"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>
                                        {props.getT(`Проблемный заказ`)}
                                        {props.counts && props.counts.problems ? "(" + props.counts.problems + ")" : ""}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/orders/shipped"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>
                                        {props.getT(`Отгружены`)}
                                        {props.counts && props.counts.shipped ? "(" + props.counts.shipped + ")" : ""}

                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/orders/outstanding"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>
                                        {props.getT("Продажа товаров")}
                                        {/* {props.counts && props.counts.outstanding
                        ? "(" + props.counts.outstanding + ")"
                        : ""} */}
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/orders/canceled"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-danger"></i>
                                    <p>
                                        {props.getT(`Отмененные`)} {props.counts && props.counts.canceled ? "(" + props.counts.canceled + ")" : ""}

                                    </p>
                                </Link>
                            </li>

                        </ul>
                    </li>
                    <li
                        className={"nav-item" + (props.menu.active === "clients" ? " menu-open" : "")}
                    >
                        <a
                            className={"nav-link" + (props.menu.active === "clients" ? " active" : "")}
                            onClick={() => props.toggleMenu("clients")}
                        >
                            <i className="nav-icon fas fa-users"></i>
                            <p>
                                {props.getT("Клиенты")}
                                <i className="right fas fa-angle-left"></i>
                            </p>
                        </a>

                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to={"/clients"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT("Клиенты")}</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/clients/add"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-success"></i>
                                    <p>{props.getT("Добавить клиента")}</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li
                        className={"nav-item" + (props.menu.active === "reports" ? " menu-open" : "")}
                    >
                        <a
                            className={"nav-link" + (props.menu.active === "reports" ? " active" : "")}
                            onClick={() => props.toggleMenu("reports")}
                        >
                            <i className="nav-icon fas fa-chart-pie"></i>
                            <p>
                                {props.getT("Отчеты")}
                                <i className="right fas fa-angle-left"></i>
                            </p>
                        </a>

                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to={"/reports/sale"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT("По товарам и услугам")}</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/reports/clients"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT("По менеджерам")}</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/reports/totals"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT("Сводный")}</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/reports/leftMake"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT("Осталось доделать")}</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/statistic/xnc-operations"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT("Статистика")}</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/statistic/xnc-operations-materials"} className="nav-link">
                                    <i className="far fa-circle nav-icon text-info"></i>
                                    <p>{props.getT("Статистика материалов")}</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={"/archive"}
                            className={"nav-link" + (props.menu.active === "archive" ? " active" : "")}
                            onClick={() => props.toggleMenu("archive")}
                        >
                            <IconFile stroke={props.menu.active === "archive" ? 'white' : '#c2c7d0'}/>
                            <p style={{marginLeft: '5px'}}>
                                {props.getT(`Архив заказов`)}


                            </p>
                        </Link>
                    </li>
                        <li className="nav-item">
                            <Link
                                to={"/material_band_connected"}
                                className={"nav-link" + (props.menu.active === "material_band_connected" ? " active" : "")}
                                onClick={() => props.toggleMenu("material_band_connected")}
                            >
                                <IconFile stroke={props.menu.active === "archive" ? 'white' : '#c2c7d0'}/>
                                <p style={{marginLeft: '5px'}}>
                                    {props.getT(`Материал <-> Кромка`)}


                                </p>
                            </Link>
                        </li>
                    </>}

                    {/*<li className={"nav-item" + (props.menu.active === "wholesale" ? " menu-open" : "")}>*/}
                    {/*    <a*/}
                    {/*        className={"nav-link" + (props.menu.active === "wholesale" ? " active" : "")}*/}
                    {/*        onClick={() => props.toggleMenu("wholesale")}*/}
                    {/*    >*/}
                    {/*        <i className="nav-icon ">$</i>*/}
                    {/*        <p>*/}
                    {/*            {props.getT("Оптовая компания")}*/}
                    {/*            <i className="right fas fa-angle-left"></i>*/}
                    {/*        </p>*/}
                    {/*    </a>*/}
                    {/*    <ul className="nav nav-treeview">*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <Link to={"/ws-company-connection-request"} className="nav-link">*/}
                    {/*                <p>{props.getT('Заявка на подключение')}</p>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <Link to={"/ws-companies"} className="nav-link">*/}
                    {/*                <p>{props.getT('Оптовые фирмы')}</p>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <Link to={"/ws-goods-stock-retail"} className="nav-link">*/}
                    {/*                <p>{props.getT('Мои остатки товаров')}</p>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</li>*/}
                    {!!(isAccessSalaryModule) ?
                        is_user_authorized ?

                        <li className={"nav-item" + (props.menu.active === "salary_project" ? " menu-open" : "")}>
                            <a
                                className={"nav-link" + (props.menu.active === "salary_project" ? " active" : "")}
                                onClick={() => props.toggleMenu("salary_project")}
                            >
                                <i className="nav-icon ">$</i>
                                <p>
                                    {props.getT("Зарплата")}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                             <SalaryItems getT={props.getT}/>
                            </ul>
                        </li>
                    : <SalaryItems getT={props.getT}/>
                        : null

                    }
                    {props.isVisibleNews && <li className="nav-item">
                        <Link
                            to={"/news"}
                            className={"nav-link" + (props.menu.active === "news" ? " active" : "")}
                            onClick={() => props.toggleMenu("news")}
                        >
                            <IconFile stroke={props.menu.active === "news" ? 'white' : '#c2c7d0'}/>
                            <p style={{marginLeft: '5px'}}>
                                {props.getT(`Новости`)}
                            </p>
                        </Link>
                    </li>}
                    <li className="nav-item">
                        <Link
                            to={"/settings-company"}
                            className={"nav-link" + (props.menu.active === "settings_company" ? " active" : "")}
                            onClick={() => props.toggleMenu("settings_company")}
                        >
                            <i className="fa fa-cog" ></i>
                            {/*<IconFile stroke={props.menu.active === "archive" ? 'white' : '#c2c7d0'}/>*/}
                            <p style={{marginLeft: '5px'}}>
                                {props.getT('Настройки фирмы')}


                            </p>
                        </Link>
                    </li>
                </ul>
            </nav>
            {/*<Payment getT={props.getT} payment={'2023-09-11 19:28:13'}/>*/}
            <Payment getT={props.getT} payment={props.user?.payment}/>
            <PaymentAdditional/>
        </div>
    </aside>);
};

const SalaryItems = ({getT})=>{
    return <>
        <li className="nav-item">
            <Link to={"/salary-project/default-plane"} className="nav-link">
                <p>{getT('План по умолчанию')}</p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to={"/salary-project/rates"} className="nav-link">
                <p>{getT('Тарифы на оплату услуг')}</p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to={"/salary-project/shift"} className="nav-link">
                <p>{getT('Смены')}</p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to={"/salary-project/ready-mark"} className="nav-link">
                <p>{getT('Отметка готовности')}</p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to={"/salary-project/scanner-ready-mark"} className="nav-link">
                <p>{getT('Сканирование')}</p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to={"/salary-project/readiness-report"} className="nav-link">
                <p>{getT('Отчёт готовности')}</p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to={"/salary-project/salary-report"} className="nav-link">
                <p>{getT('Отчёт по зарплате')}</p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to={"/salary-project/orders"} className="nav-link">
                <p>{getT('Заказы')}</p>
            </Link>
        </li>
        {!!localStorage.getItem(LS_WORKER_ID) &&  <li className="nav-item">
            <Link to={"/salary-project/personal-data"} className="nav-link">
                <p>{getT('Личные данные')}</p>
            </Link>
        </li>}
    </>

}
export default AsideMenu;
