import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getWSPartnerPropertiesAddModal} from "../../../../store/selectors/wholesalary_selector";
import Modal from "../../MyModal/Modal";
import {wholesalePartnerPropertiesAddModalAC} from "../../../../store/actions/WholesaleActions";
import PartnerPropertyForm from "./PartnerPropertyForm";
import {setPartnerPropertiesThunk} from "../../../../store/thunks/wholesale/ws_partner_properties_thunk";

const PartnerPropertiesAddModal = ({getT}) => {
    const dispatch = useDispatch();
    const properties_modal = useSelector(getWSPartnerPropertiesAddModal);
    if (!properties_modal?.isOpen) return null;

    const handlerClose = () => {
        dispatch(wholesalePartnerPropertiesAddModalAC({isOpen: false, property: null, firm_main: null}))
    }
    const handlerSend = data => {
        let payload = Object?.keys(data)?.reduce((acc, key)=>{
            acc[key] = Number(data?.[key]);
            return acc
        }, {});
        dispatch(setPartnerPropertiesThunk({...payload, firm_main: properties_modal?.firm_main}))
    }
    return (
        <Modal open={properties_modal.isOpen} onClose={handlerClose} title={getT("Свойства фирмы")}>
            <PartnerPropertyForm getT={getT} handlerSend={handlerSend} initail_value={properties_modal?.property}/>
        </Modal>
    );
};

export default PartnerPropertiesAddModal;