import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getWSConnectionClient,
    getWSPartnerFirmList,
    getWSPartnerProperties
} from "../../store/selectors/wholesalary_selector";
import {getWSPartnerFirmListThunk} from "../../store/thunks/wholesale/partner_firms_thunk";
import {
    getWSConnectionClientThunk,
    setWSConnectionClientThunk
} from "../../store/thunks/wholesale/connection_client_thunk";
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";
import Button from "../../components/ui/Button";
import {LS_FIRM} from "../../utils/constants";
import ItemWsCompany from "./components/ItemWSCompany";
import {useHistory} from "react-router-dom";
import {getPartnerPropertiesThunk} from "../../store/thunks/wholesale/ws_partner_properties_thunk";
import PartnerPropertiesAddModal
    from "../../components/Modals/WholeSale/PartnerPropertiesAddModal";
import {wholesalePartnerPropertiesAddModalAC} from "../../store/actions/WholesaleActions";



const WsCompaniesPage = ({getT}) => {
    const dispatch = useDispatch();
    const partner_firm = useSelector(getWSPartnerFirmList);
    const connection_partner_firm = useSelector(getWSConnectionClient);
    const partner_properties = useSelector(getWSPartnerProperties)
    let firm_current = localStorage.getItem(LS_FIRM);
    const history = useHistory();

    useEffect(() => {
        dispatch(getPartnerPropertiesThunk())
        dispatch(getWSPartnerFirmListThunk());
        dispatch(getWSConnectionClientThunk());
    }, []);
    const handlerCompanyApply = (item) => e => {
        let data = {
            active: 0,
            client: null,
            main_firm: item?.firm_id,
            partner_firm: firm_current
        }

        dispatch(setWSConnectionClientThunk(data))
    }
    const navigateToProductsFirmById = ({main_firm, active=0, firm_name}) => ()=>{
        history.push({
            pathname: `/ws-products-company/${main_firm}`,
            search: `?active=${active}`, // Передаем query-параметры
            state: { firm_name: firm_name}
        });
    };
    const handlerOpenModalPartnerProperty = ({firm_main, property})=> (e)=>{
        dispatch(wholesalePartnerPropertiesAddModalAC({isOpen: true, property: property, firm_main}))
    }
    console.log('partner_properties', partner_properties)
    return (
        <ContainerContent>

            <PageTitle title={"ws_companies"}/>

            <div className="container-fluid ">
                <div className="row">
                    {partner_firm?.data?.map(item => {
                        let connection = connection_partner_firm?.filter(e=>{
                            let main_firm = Number(e?.main_firm);
                            let partner_firm = Number(e?.partner_firm);
                            return (Number(firm_current) === partner_firm) && (Number(item?.firm_id) === main_firm)
                        })
                        let is_connection_partner = connection?.every(e => {
                            return (!!Number(e?.active))
                        }) && !!connection?.length;

                        let is_awaiting_request = connection?.some(e => {
                            return (!Number(e?.active))
                        })
                        let property = partner_properties?.find(e=> Number(e?.firm_main) === Number(item?.firm_id)) ?? null
                        console.log('property', property)
                        return <ItemWsCompany item={item} getT={getT} is_connection_partner={is_connection_partner}
                                              handlerCompanyApply={handlerCompanyApply(item)}
                                              is_awaiting_request={is_awaiting_request}
                                              navigateToProductsFirmById={navigateToProductsFirmById({
                                                  main_firm: item?.firm_id,
                                                  active: Number(is_connection_partner),
                                                  firm_name: item?.firm_name
                                              })}
                                              handlerOpenModalPartnerProperty={handlerOpenModalPartnerProperty({firm_main: item?.firm_id, property: property})}

                        />
                    })}
                </div>
            </div>

            <PartnerPropertiesAddModal key={'PartnerPropertiesAddModal'}/>
        </ContainerContent>
    );
};

export default WsCompaniesPage;